@import "../../assets/scss/variables";

.reports{
  &-list{
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    &:not(:first-child){
      .reports-table{
        border-radius: 0 0 4px 4px;
      }
    }
  }
  &-table{
    position: relative;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    &:not(:last-child){
      margin-bottom: 30px;
    }
    &__thead{
      position: relative;
      margin-bottom: 1px;
      .reports-table{
        &__row{
          background: $colorLightGrey;
          min-height: 40px;
          border-radius: 4px;
        }
        &__col{
          color: $colorLightText;
        }
      }
    }
    &__tbody{
      position: relative;
      .reports-table{
        &__row{
          &:hover{
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.24);
            z-index: 1;
          }
        }
      }
      .report{
        &-name{
          font-weight: 700;
        }
      }
    }
    &__row{
      position: relative;
      display: flex;
      align-items: center;
      background: #fff;
      min-height: 64px;
      color: $colorText;
      transition: all 0.3s ease;
      overflow: hidden;
      z-index: 0;
      &:not(:last-child){
        margin-bottom: 1px;
      }
      &:hover{
        border-radius: 4px;
        .btn{
          //opacity: 1;
          right: 8px;
        }
      }
    }
    &__col{
      position: relative;
      padding: 8px 8px;
    }
    .report{
      &-check{
        width: 35px;
        padding-right: 5px;
      }
      &-date{
        width: 144px;
      }
      &-name{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
      }
      &-place{
        width: 274px;
        color: $colorLightText;
        word-break: break-word;
      }
      &-city{
        width: 152px;
        color: $colorLightText;
        word-break: break-word;
      }
      &-status{
        width: 280px;
        color: $colorLightText;
      }
    }
    [class*="sort"]{
      font-size: 24px;
      transition: color 0.3s ease;
      cursor: pointer;
      &:hover{
        color: $colorText;
      }
    }
    .btn{
      position: absolute;
      top: 8px;
      bottom: 8px;
      right: -150px;
      width: 120px;
      z-index: 1;
      //opacity: 0;
    }
  }
  &-foot{
    position: relative;
    display: flex;
    align-items: center;
    z-index: 0;
    .btn{
      width: 270px;
    }
  }
}
