@import "../../assets/scss/variables";

.order{
  &-step{
    position: relative;
    margin-bottom: 10px;
    ul{
      display: flex;
      align-items: center;
      counter-reset: count;
      li{
        position: relative;
        display: flex;
        align-items: center;
        height: 48px;
        color: $colorLightText;
        font-size: 16px;
        padding-left: 40px;
        margin-right: 15px;
        counter-increment: count;
        overflow: hidden;
        transition: all 0.3s ease;
        cursor: pointer;
        &:before{
          content: counter(count);
          position: absolute;
          background: $colorLightGrey;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: 50%;
          width: 32px;
          height: 32px;
          text-align: center;
          border-radius: 50%;
          transform: translateY(-50%);
          transition: all 0.3s ease;
        }
        &:hover{
          color: $colorOrange;
          &:before{
            background: $colorOrange;
            color: #fff;
          }
        }
        &.active{
          font-size: 24px;
          font-weight: 300;
          padding-left: 56px;
          margin-right: 40px;
          &:before{
            width: 48px;
            height: 48px;
          }
        }
        &.complete{
          width: 32px;
          padding-left: 32px;
          margin-right: 8px;
        }
      }
    }
  }
}
