@media screen and (max-width: 1679px){
  .events{
    &-table{
      &__thead{
        .events-table{
          &__col{
            font-size: 14px;
          }
        }
      }
      .event{
        &-date{
          width: 130px;
        }
        &-place{
          width: 220px;
        }
        &-status{
          width: 135px;
        }
      }
    }
  }
}

@media screen and (max-width: 1439px){
  .events{
    &-list{
      &:not(:last-child){
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .events{
    &-table{
      &__thead{
        .events-table{
          &__col{
            font-size: 12px;
          }
        }
      }
      &__col{
        font-size: 14px;
      }
      .event{
        &-date{
          width: 120px;
        }
        &-place{
          width: 180px;
        }
        &-city{
          width: 110px;
        }
        &-status{
          width: 120px;
        }
      }
      [class*="sort"]{
        font-size: 20px;
        margin-top: -2px;
      }
    }
  }
}

