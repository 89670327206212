@media screen and (max-width: 1199px){
  .order-payment{
    &__group{
      padding: 10px 0;
    }
    &__col{
      padding: 10px 10px;
    }
  }
}
