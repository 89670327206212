@media screen and (max-width: 1199px){
  .order-payment-basket{
    &__box{
      padding: 20px 0;
      &:first-child{
        padding-top: 0;
      }
    }
    &__bg{
      top: 60px;
      font-size: 240px;
    }
  }
}
