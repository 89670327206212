@media screen and (max-width: 1439px){
  .event-order__search{
    &-event{
      width: 220px;
    }
    &-event
    &-field,
    &-date{
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
