@import "../../assets/scss/variables";

.pagination{
  position: relative;
  margin-left: auto;
  ul{
    display: flex;
    align-items: center;
    li{
      position: relative;
      &:not(:last-child){
        margin-right: 8px;
      }
      &.page{
        &-prev{
          .icon{
            transform: rotate(90deg);
          }
        }
        &-next{
          .icon{
            transform: rotate(-90deg);
          }
        }
      }
      a,
      span:not(.icon){
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        color: $colorText;
        font-size: 16px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid $colorGrey;
        transition: all 0.3s ease;
        &:hover{
          border-color: $colorYellow;
        }
        &.active{
          font-weight: 700;
          border-color: $colorYellow;
        }
        .icon{
          font-size: 24px;
        }
      }
    }
  }
}
