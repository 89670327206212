@import "../../assets/scss/variables";

.header{
  position: fixed;
  display: flex;
  align-items: center;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.23913);
  padding: 10px 3.125vw;
  z-index: 20;
  &-left,
  &-right{
    position: relative;
    display: flex;
    align-items: center;
  }
  &-right{
    margin-left: auto;
  }
  .btn{
    padding: 0 10px;
    margin-right: 15px;
  }
}
.logo-short{
  position: relative;
  width: 53px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 30px;
}
.search{
  position: relative;
  margin-right: 25px;
  input{
    width: 350px;
    border-radius: 20px;
    padding-left: 50px;
  }
  .icon{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    color: $colorLightText;
    font-size: 24px;
    text-align: center;
    padding-left: 15px;
  }
  &-place{
    position: relative;
    margin-right: 10px;
    &.show{
      .search{
        &-icon{
          .icon{
            color: $colorLightText;
            border-radius: 50%;
            border: 1px solid $colorGrey;
            z-index: 11;
          }
        }
        &-place{
          &-modal{
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    &-modal {
      position: absolute;
      background: #fff;
      top: -8px;
      left: -8px;
      width: 365px;
      border-radius: 8px;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
      padding: 8px 0;
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;
      z-index: 10;
      &__head {
        position: relative;
        padding: 0 8px 0 63px;
      }
      &__list{
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 294px;
        padding-top: 10px;
        .ps--active-y{
          padding-right: 24px;
        }
        &-box{
          position: relative;
          color: $colorLightText;
          line-height: 1.5;
          border-bottom: 1px solid $colorLightGrey;
          padding: 12px 18px;
          transition: color 0.3s ease;
          cursor: pointer;
          &:hover{
            color: $colorText;
          }
          &.active{
            background: $colorLightYellow;
            color: #fff;
          }
        }
      }
    }
  }
  &-date{
    position: relative;
    margin-right: 10px;
    &.show{
      .search{
        &-icon{
          .icon{
            color: $colorLightText;
            border-radius: 50%;
            border: 1px solid $colorGrey;
            z-index: 11;
          }
        }
        &-date{
          &-modal{
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    &-modal{
      position: absolute;
      background: #fff;
      top: -8px;
      left: -8px;
      width: 365px;
      border-radius: 8px;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
      padding: 8px;
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;
      z-index: 10;
      &__title{
        position: relative;
        font-size: 24px;
        font-weight: 300;
        padding: 5px 0 5px 55px;
      }
      &__calendar{
        position: relative;
        padding: 0 40px;
      }
    }
  }
  &-icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: $colorLightText;
    font-size: 24px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid $colorGrey;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover{
      color: $colorText;
      border-color: $colorLightText;
    }
    &.show{
      width: auto;
      min-width: 40px;
    }
    .icon{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: auto;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50%;
    }
    &__date{
      position: relative;
      color: $colorText;
      font-size: 16px;
      margin-right: 18px;
    }
  }
}
.top-menu{
  position: relative;
  margin-right: 30px;
  ul{
    display: flex;
    li{
      position: relative;
      a{
        display: block;
        color: $colorText;
        line-height: 40px;
        border-radius: 20px;
        padding: 0 15px;
        transition: all 0.3s ease;
        &:hover{
          color: $colorOrange;
        }
        &.active{
          background: $colorGrey;
          color: #fff;
          pointer-events: none;
        }
      }
    }
  }
}
.notice{
  position: relative;
  margin-right: 15px;
  &-icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $colorGrey;
    width: 48px;
    height: 48px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover{
      background: $colorLightText;
    }
  }
    &-count{
      position: absolute;
      background: $colorYellow;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      border-radius: 50%;
      overflow: hidden;
    }
  &-modal {
    position: fixed;
    background: #fff;
    top: 70px;
    width: 365px;
    border-radius: 8px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    padding: 8px 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(calc(48px - 100%));
    transition: all 0.3s ease;
    z-index: 10;
    &.show {
      opacity: 1;
      visibility: visible;
    }
    &__title {
      position: relative;
      color: $colorLightText;
      font-weight: 800;
      padding: 15px 15px 10px;
      .icon{
        top: -2px;
        font-size: 24px;
        margin-right: 5px;
      }
    }
    &__list {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 280px;
      overflow: hidden;
      .ps--active-y{
        padding-right: 24px;
      }
    }
      &__box{
        position: relative;
        border-bottom: 1px solid $colorLightGrey;
        padding: 8px 26px 8px 18px;
        cursor: pointer;
        &:hover{
          .notice-modal__box{
            &-text{
              color: $colorText;
            }
          }
        }
        &.new{
          &:before{
            content: '';
            position: absolute;
            background: $colorYellow;
            top: 50%;
            right: 8px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
        }
        &-date{
          position: relative;
          color: $colorGrey;
          font-size: 12px;
        }
        &-text{
          position: relative;
          color: $colorLightText;
          font-size: 16px;
          line-height: 1.5;
          transition: color 0.3s ease;
        }
      }
  }
}
.user{
  position: relative;
  &-icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $colorLightBlue;
    width: 48px;
    height: 48px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50%;
    transition: all 0.3s ease;
    overflow: hidden;
    cursor: pointer;
  }
  &-modal{
    position: fixed;
    background: #fff;
    top: 70px;
    width: 365px;
    border-radius: 8px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
    padding: 8px 0;
    opacity: 0;
    visibility: hidden;
    transform: translateX(calc(48px - 100%));
    transition: all 0.3s ease;
    z-index: 10;
    &.show{
      opacity: 1;
      visibility: visible;
    }
    &__title{
      position: relative;
      color: $colorLightText;
      font-weight: 800;
      padding: 15px 18px 10px;
    }
    &__list{
      position: relative;
      ul{
        li{
          position: relative;
          border-bottom: 1px solid $colorLightGrey;
          a{
            display: flex;
            align-items: center;
            height: 56px;
            color: $colorLightText;
            padding: 8px 15px 8px 54px;
            transition: color 0.3s ease;
            &:hover{
              color: $colorText;
            }
            .icon{
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              left: 0;
              top: 50%;
              width: 54px;
              height: 56px;
              font-size: 24px;
              transform: translateY(-50%);
            }
           }
        }
      }
    }
  }
}
.menu-toggle{
  position: relative;
  display: none;
  font-size: 24px;
  margin-left: 15px;
  transition: color 0.3s ease;
  cursor: pointer;
  &:hover{
    color: $colorOrange;
  }
}


._active {
  background: linear-gradient(45deg, #bea0a0, #e15d5d00);
}