@import "../../assets/scss/variables";

.order-layout-ticket{
  position: relative;
  .form{
    &-button{
      text-align: left;
      .btn{
        width: 280px;
      }
    }
  }
}
