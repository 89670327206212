@import "../../assets/scss/variables";

.event-menu{
  position: relative;
  height: 40px;
  border-top: 1px solid #DBDBDB;
  padding: 0 3.125vw;
  ul{
    display: flex;
    li{
      position: relative;
      &:not(:last-child){
        margin-right: 20px;
      }
      a{
        position: relative;
        display: flex;
        align-items: center;
        height: 40px;
        color: $colorLightText;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 0 8px;
        transition: color 0.3s ease;
        &.active{
          &:before{
            content: '';
            position: absolute;
            background: $colorOrange;
            left: 0;
            right: 0;
            bottom: 0;
            height: 4px;
          }
        }
        &:hover{
          color: $colorLightOrange;
        }
      }
    }
  }
}
