@import "../../assets/scss/variables";

.order-basket{
  position: relative;
  border-top: 1px solid $colorLightLabel;
  &__list{
    color: #000;
    font-size: 16px;
    padding: 30px 0;
  }
    &__row{
      position: relative;
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: 15px;
      }
    }
    &__col{
      position: relative;
      &:first-child{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        padding-right: 15px;
      }
      &:last-child{
        width: 140px;
        text-align: right;
      }
    }
  .price{
    color: $colorYellow;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
  }
  b{
    color: $colorText;
    font-weight: 700;
  }
}
