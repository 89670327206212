@import "../../assets/scss/variables";

.order-print{
  &-error {
    color: rgb(240, 30, 30);
  }
  position: relative;
  padding-top: 10px;
  .form{
    &-group{
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    &-button{
      display: flex;
      .btn{
        &:not(:last-child){
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: 0;
        }
        &-link{
          width: 180px;
        }
      }
    }
  }
}
