@media screen and (max-width: 1199px){
  .order-step{
    ul{
      li{
        &.active{
          margin-right: 20px;
        }
      }
    }
  }
}
