@import "../../assets/scss/variables";

.order-action{
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  &__title{
    position: relative;
    color: $colorBlue;
    font-size: 16px;
    font-weight: 800;
    margin-right: 8px;
  }
  &__select{
    width: 440px;
  }
  .select{
    &__input{
      input{
        height: 46px !important;
      }
    }
    &__value{
      &-container{
        input{
          height: 46px !important;
        }
      }
    }
    &__option{
      padding: 14px 15px !important;
    }
  }
}
