@import "../../assets/scss/variables";

.events{
  &-list{
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    &:not(:last-child){
      margin-right: 25px;
    }
  }
  &-table{
    position: relative;
    &__thead{
      position: relative;
      margin-bottom: 1px;
      .events-table{
        &__row{
          background: $colorLightGrey;
          min-height: 40px;
        }
        &__col{
          color: $colorLightText;
        }
      }
    }
    &__tbody{
      position: relative;
      //max-height: 780px;
      .events-table{
        &__row{
          &:hover{
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.24);
            z-index: 1;
          }
        }
      }
      .event{
        &-name{
          font-weight: 700;
        }
      }
    }
      &__row{
        position: relative;
        display: flex;
        align-items: center;
        background: #fff;
        min-height: 64px;
        color: $colorText;
        border-radius: 4px;
        transition: all 0.3s ease;
        overflow: hidden;
        z-index: 0;
        &:not(:last-child){
          margin-bottom: 1px;
        }
        &:hover{
          .btn{
            //opacity: 1;
            right: 8px;
          }
        }
      }
      &__col{
        position: relative;
        padding: 8px 8px;
      }
    .event{
      &-date{
        width: 144px;
      }
      &-name{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
      }
      &-place{
        width: 274px;
        color: $colorLightText;
        word-break: break-word;
      }
      &-city{
        width: 152px;
        color: $colorLightText;
        word-break: break-word;
      }
      &-status{
        width: 280px;
        color: $colorLightText;
      }
    }
    [class*="sort"]{
      font-size: 24px;
      transition: color 0.3s ease;
      cursor: pointer;
      &:hover{
        color: $colorText;
      }
    }
    .btn{
      position: absolute;
      top: 8px;
      bottom: 8px;
      right: -150px;
      width: 120px;
      z-index: 1;
      //opacity: 0;
    }
  }
}
