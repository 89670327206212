@import "../../assets/scss/variables";

.event-map__sidebar-tickets{
  position: relative;
  background: #fff;
  width: 490px;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.23913);
  padding: 25px 15px 25px 25px;
  &-head{
    position: relative;
    width: 100%;
    color: $colorBlue;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 15px;
    .icon{
    }
  }
    &-toggle{
      position: absolute;
      background: transparent;
      top: 50%;
      right: -10px;
      font-size: 46px;
      line-height: 0;
      border: 0;
      padding: 0;
      margin: 0;
      transform: translateY(-50%);
      cursor: pointer;
      overflow: hidden;
      transition: color 0.3s ease;
      &:hover{
        color: $colorLightBlue;
      }
      &.active{
        .icon{
          top: 3px;
          transform: rotateX(180deg);
        }
      }
      .icon{
        top: -3px;
        display: inline-block;
      }
    }
  &-body{
    position: relative;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    margin-bottom: 20px;
  }
    &-list{
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 500px;
      max-height: calc(100vh - 180px - 250px);
      .scrollbar-container{
        padding-right: 40px;
      }
    }
  &-foot{
    position: relative;
    width: 100%;
  }
    &-total{
      position: relative;
      color: $colorLightText;
      font-size: 16px;
      margin-bottom: 15px;
    }
    &-button{
      position: relative;
      z-index: 1;
      .btn:first-child {
        margin-right: 20px;
        width: 270px;
      }
    }
}

body > [class^="css-"] {
  z-index: 555555555555555555555555!important;
}