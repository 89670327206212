@import "../../assets/scss/variables";

.order{
  &-list{
    position: relative;
    color: $colorLightText;
    font-size: 16px;
    &__head{
      position: relative;
      .order-list{
        &__row{
          background: $colorLightGrey;
          border-radius: 4px;
        }
        &__col{
          padding: 10px 12px;
          &:first-child{
            padding-left: 8px;
          }
          &:last-child{
            padding-right: 8px;
          }
        }
      }
    }
    &__body{
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 325px;
      .order-list{
        &__row{
          min-height: 60px;
          border-bottom: 1px solid $colorGrey;
        }
      }
      .ps{
        &__rail-y{
          width: 8px !important;
        }
        &__thumb-y{
          right: 0 !important;
        }
      }
    }
    &__foot{
      position: relative;
      padding-top: 30px;
      .order-list{
        &__col {
          padding: 10px 12px;
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
    &__row{
      position: relative;
      display: flex;
      align-items: center;
    }
    &__col{
      position: relative;
      padding: 12px;
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
    .place-box{
      position: relative;
      &:not(:last-child){
        margin-right: 10px;
      }
    }
    b{
      color: $colorText;
      font-weight: 700;
    }
    .discount{
      color: $colorRed;
      font-size: 12px;
      margin-right: 10px;
    }
  }
  &-check{
    padding-right: 5px;
  }
  &-place{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &-price{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    width: 200px;
    text-align: right;
  }
  &-discount{
    width: 304px;
  }
  &-promo{
    width: 140px;
    input{
      text-transform: uppercase;
      padding-right: 40px;
      &[disabled]{
        & ~ button{
          pointer-events: none;
        }
      }
    }
    button{
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      width: 40px;
      height: 40px;
      color: $colorText;
      font-size: 24px;
      border: 0;
      padding: 0;
      cursor: pointer;
      transition: color 0.3s ease;
      &:hover{
        color: $colorOrange;
      }
    }
  }
  &-text{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    text-align: right;
  }
}

.spinner-screen-order {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999999;
}