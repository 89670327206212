@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot');
  src:  url('icomoon.eot#iefix') format('embedded-opentype'),
    url('icomoon.ttf') format('truetype'),
    url('icomoon.woff') format('woff'),
    url('icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arr-right:before {
  content: "\e90b";
}
.icon-arr-left:before {
  content: "\e915";
}
.icon-exit:before {
  content: "\e913";
}
.icon-settings:before {
  content: "\e914";
}
.icon-statistics:before {
  content: "\e912";
}
.icon-spinner:before {
  content: "\e911";
}
.icon-menu:before {
  content: "\e910";
}
.icon-check:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-marker:before {
  content: "\e90e";
}
.icon-view-on:before {
  content: "\e900";
}
.icon-view-off:before {
  content: "\e901";
}
.icon-sort:before {
  content: "\e902";
}
.icon-refresh:before {
  content: "\e903";
}
.icon-plus:before {
  content: "\e904";
}
.icon-minus:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-dd:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-cart:before {
  content: "\e909";
}
.icon-bell:before {
  content: "\e90a";
}
.icon-cash:before {
  content: "\e916";
}
.icon-bank-card:before {
  content: "\e917";
}
.icon-certificate:before {
  content: "\e918";
}
.icon-bonus:before {
  content: "\e919";
}
.icon-dual-card:before {
  content: "\e91a";
}
