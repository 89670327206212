@media screen and (max-width: 1439px){
  .event-panel__statistics{
    &-box{
      &:not(:last-child){
        margin-right: 30px;
      }
      b{
        display: block;
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 1199px){
  .event-panel__statistics{
    display: block;
    width: 340px;
    column-count: 2;
    column-gap: 15px;
    text-align: right;
    &-box{
      display: inline-block;
      &:nth-child(n){
        margin-right: 0;
      }
      b{
        display: inline-block;
        min-width: 60px;
        margin-left: 0;
      }
    }
  }
}
