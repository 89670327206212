@import "../../assets/scss/variables";

.event-order{
  position: relative;
  padding: 15px 3.125vw;

  position: relative;
  display: flex;
  align-items: flex-start;
}
