@media screen and (max-width: 1439px){
  .event{
    &-back{
      margin-right: 15px;
    }
    &-map{
      &__sidebar{
        bottom: 15px;
        &-plan{
          margin-right: 15px;
        }
      }
    }
  }
}

#scopeContainer svg {
  width: 240px;
  height: 168px;
}
#svg-map svg {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.scope-border {
  position: absolute;
  top: 0px;
}
.scope-border rect {
  fill: none;
  stroke: orange;
}
.event-map__sidebar-plan-view svg {
  height: 100%;
}
@media screen and (max-width: 1199px){
  .event{
    &-panel{
      .event-box{
        flex-wrap: wrap;
        width: 320px;
        &__title{
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &__place{
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .event {
    &-back {
      width: 30px;
      height: 30px;
      font-size: 30px;
      margin-right: 10px;
    }
  }
}

.legend {
  z-index: 55;
    position: fixed;
    right: 0px;
    background: #fff;
    max-width: 320px;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.23913);
    padding: 5px 5px 5px 5px;
    margin-top: 5px;
    &-disabled {
      display: none;
    }
}