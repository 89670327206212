@import "../../assets/scss/variables";

.reserve-types{
  .active {
    background: linear-gradient(to right, #F2AD30, #E2441F);
    border-radius: 5px;
  }
  position: relative;
  &__list{
    position: relative;
    column-count: 3;
    padding: 15px 0;
  }
    &__box{
      position: relative;
      display: inline-flex;
      align-items: center;
      vertical-align: top;
      width: 100%;
      font-size: 16px;
      margin-bottom: 15px;
      &-color{
        position: relative;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 15px;
      }
      &-title{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
      }
    }
  .form {
    &-error {
      color: rgb(255, 0, 128);
    }
    &-button{
      display: flex;
      .btn{
        width: 280px;
        padding: 0 10px;
        &:not(:last-child){
          margin-right: 15px;
        }
        .icon{
          font-size: 24px;
          margin-right: 5px;
        }
      }
    }
  }
}
