.reports-filter{
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 30px 18px;
  &_flex {
    display: flex;
  }
  &__container {
    margin-bottom: 10px;
    display: flex;
  }
  .select__value-container {
    height: 46px;
  }
  &-line {
    height: 1px;
    margin: 0 20px;
    background: #837F8D;
  }
  .form-button {
    width: 50%;
  }
  .event-order__search-date-title {
    height: 48px;
  }
  .event-order__search-date {
    margin-right: 0px;
  }
  .form-button {
    &:nth-child(odd) {
      margin-right: 15px;
    }
    &:nth-child(even) {
      margin-left: 15px;
    }
  }
  &__search {
    &-field {
      &-full {
        margin: 0!important;
      }
      &:nth-child(odd) {
        margin-right: 15px;
      }
      &:nth-child(even) {
        margin-left: 15px;
      }
      // min-width: 300px;
      width: 100%;
      input {
        height: 46px; 
      }
    }
  }
  &:not(:last-child){
    border-radius: 4px 4px 0 0;
  }
  &__period{
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    padding-right: 30px;
    &-list{
      position: relative;
      ul{
        li{
          position: relative;
          &:not(:last-child){
            margin-bottom: 20px;
          }
          b{
            margin-right: 20px;
          }
          .checkbox{
            &:not(:last-child){
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  &__tickets{
    position: relative;
    display: flex;
    width: 55%;
  }
    &__group{
      position: relative;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      &:not(:last-child){
        padding-right: 30px;
      }
      &-list{
        position: relative;
        ul{
          li{
            position: relative;
            &:not(:last-child){
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    &__title{
      position: relative;
      font-weight: 700;
      margin-bottom: 15px;
    }
}
