@media screen and (max-width: 1679px){
  .event-orders__filter{
    &-type{
      width: 360px;
    }
  }
}

@media screen and (max-width: 1439px){
  .event-orders__filter{
    &-select{
      width: 220px;
      &:not(:last-child){
        margin-right: 15px;
      }
    }
    &-type{
      width: 250px;
    }
  }
}
