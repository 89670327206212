@import "../../assets/scss/variables";

.reserve-type{
  position: relative;
  .form{
    &-button{
      position: relative;
      display: flex;
      .btn{
        width: 280px;
        &-link{
          width: auto;
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: 0;
        }
      }
    }
  }
  label{
    display: block;
    margin-bottom: 10px;
  }
}

.photoshop-picker{
  width: 100% !important;
  background: transparent !important;
  box-shadow: none !important;
  & > div{
    &:first-child{
      display: none !important;
    }
  }
  & > .flexbox-fix{
    padding: 0 !important;
    & > div{
      &:first-child{
        width: 275px !important;
        height: 275px !important;
        border: 0 !important;
        .saturation-white{
          & > div{
            &:last-child{
              & > div{
                border: 4px solid #fff !important;
                width: 18px !important;
                height: 18px !important;
                border-radius: 50% !important;
                box-shadow: 0 0 1px #000000 !important;
              }
            }
          }
        }
      }
      &:not(:first-child){
        &:not(:last-child){
          height: 275px !important;
          width: 14px !important;
          border: 0 !important;
          margin-left: 45px !important;
          .hue-vertical{
            & > div{
              & > div{
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 4px solid #fff !important;
                box-shadow: 0 0 1px #000000 !important;
                margin-left: -2px;
                margin-top: -9px;
              }
            }
          }
        }
      }
      &:last-child{
        width: 80px !important;
        margin-left: 45px !important;
        & > .flexbox-fix{
          & > div {
            &:first-child{
              width: 80px !important;
              & > div{
                & > div{
                  &:first-child,
                  &:last-child{
                    display: none !important;
                  }
                  &:not(:first-child) {
                    &:not(:last-child) {
                      margin: 0 !important;
                      border-color: rgb(179, 179, 179) !important;
                      & > div{
                        height: 60px !important;
                        box-shadow: none !important;
                        &:last-child{
                          display: none !important;
                        }
                      }
                    }
                  }
                }
              }
            }
            &:last-child{
              display: none !important;
            }
          }
        }
      }
    }
  }
}
