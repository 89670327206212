h2 {
    font-weight: 600;
    padding: 15px 0 0 15px;
    font-size: 22px;
}

.analytic {
    &:first-child {
        margin-bottom: 5px;
    }

    &-sales {
        border: 2px solid #dfdfdf;
        border-radius: 1%;
        min-height: 302px;
        .container {
            display: flex;
            justify-content: space-between;
            text-align: center;
        }

        .block {
            margin: 0 40px;

            &_text {
                font-size: 30px;
                font-weight: 500;
            }

            &_text_green {
                color: green;
                font-weight: 600;
                font-size: 36px;
            }

            &_text_red {
                color: red;
                font-weight: 600;
                font-size: 36px;
            }
        }
    }
}

.blocks {
    display: flex;

    &-sales,
    &-map,
    &-coast {
        min-width: 580px;
        // max-width: 70vw;
        width: 100%;
        background: white;
        // min-width: 500px;
        // width: 70vw
            // max-width: 1400px;;
        // margin: auto;
    }

    &-map {
        display: flex;
        // padding: 10px;
        position: relative;
        height: 700px;
        border: 2px solid #dfdfdf;
        border-radius: 1%;

        h2 {
            position: absolute;
            z-index: 1;
        }
    }

    &-canal {
        border: 2px solid #dfdfdf;
        margin-left: 5px;
        border-radius: 1%;
        height: 100%;
        width: 30vw;
        max-width: 600px;
        min-width: 510px;
        background: white;
    }

    &-coast {
        .container {
            width: 100%;
        }

        margin-top: 5px;
        display: flex;
        width: 100%;
        position: relative;
        // height: 700px;
        border: 2px solid #dfdfdf;
        border-radius: 1%;

        &_header {
            display: flex;

            &_name {
                width: 40%;
            }

            &_qnt {
                width: 15%;
            }

            &_name,
            &_qnt {
                font-size: 24px;
                font-weight: 500;
            }

            &_coast {
                width: 15%;
            }

            &_prc {
                width: 30%;
            }

            &_coast,
            &_prc {
                font-size: 24px;
            }
        }

        &_item {
            &:hover {
                background: #e5e5e5 !important;
            }

            display: flex;
            align-items: center;
            padding: 10px 10px;
            border: 1px solid #e5e5e5;
            border-radius: 5%;
            margin: 2px 0px;

            &_name {
                width: 40%;
            }

            &_qnt {
                width: 15%;
                font-weight: 500;
            }

            &_name,
            &_qnt {
                font-size: 22px;
            }

            &_coast {
                width: 15%;
            }

            &_prc {
                width: 30%;

                &_container {
                    width: 125px;
                    background: #c1d9ff;
                    text-align: center;
                    padding: 5px 15px;
                    border-radius: 15%;
                }
            }

            &_coast,
            &_prc {
                font-size: 22px;
            }
        }
    }
}

.blocks {
    //     max-width: 1400px;;
    // min-width: 1200px;
    // width: 95vw;
    // margin-left: auto;
    // margin-right: auto;
}

.analytic_container {

    // display: flex;
    // justify-content: center;
    .filter {
        position: relative;
        // min-width: 100vw;
        //     max-width: 1400px;;
        // min-width: 1200px;
        // width: 95vw;
        margin: auto;

        &-btns {
            height: 50px;
            position: absolute;
            right: 0px;

            button {

                background: linear-gradient(to right, #E2441F, #F2AD30);
                margin-bottom: 10px;
                height: -moz-fit-content;
                height: fit-content;
                max-width: 200px;
                margin: 0 15px;

            }
        }

        background: #fff;
        border-radius: 1%;
        border: 2px solid #dfdfdf;
        margin-bottom: 5px;
        padding: 10px 10px 0 10px;

        li {
            padding-bottom: 10px;
        }
    }
}

.dynamic-sales {
        // max-width: 1400px;;
    margin-left: auto;
    margin-right: auto;

    &_container {
        border: 2px solid #dfdfdf;
        // margin-left: 5px;
        margin: 5px 0;
        border-radius: 1%;
        height: 100%;
        width: 100%;
        background: white;
        min-height: 200px;
    }
}

.dynamic-list-country {
    width: 400px !important;
}

.dynamic-list-char,
.dynamic-list-country {
    width: 100%;

    &_item {
        align-items: center;

        &:hover {
            background: #e4e4e4 !important;
            z-index: 5454;

            .dynamic-list-char_item_country {
                background: #e4e4e4 !important;

            }
        }

        &_container {
            position: initial;
            display: flex;
            height: 100%;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 100%;
            max-width: 250px;
            min-width: 250px;
        }

        &_container2 {
            position: initial;
            display: flex;
            height: 100%;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 100%;
            max-width: 300px;
            min-width: 260px;

            .dynamic-list-char_item_name {
                min-width: 200px;
            }
        }

        font-size: 18px;
        display: flex;
        position: relative;

        &_name {
            // white-space: pre;
            padding-left: 5px;
            display: flex;
            height: 100%;
            flex: 1 1 auto;
            width: 100%;
            text-overflow: clip;
            max-width: 188px;
            overflow: hidden;
            text-align: initial;
        }

        &_count {
            width: 66px;
            align-self: center;
        }

        &_country {
            background: #f9f9f9;
            position: relative;
            display: flex;
            height: 100%;
            padding: 0 10px;
            width: 100px;
            text-align: center;
            place-content: center;

            .dynamic-list-char_item {
                width: 100px;
            }
        }

        &_line {
            width: 100%;
            background: #f9f9f9;
            // top: 4px;
            position: relative;
            display: flex;
            flex: 1 0 auto;
            height: 100%;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 100%;
            height: 12px;
            min-width: 70px;

            div {
                background: #3366cc;
            }
        }
    }
}

.analytic_container {
    .container {
        padding: 15px !important;
    }

    .centerLoader {
        width: 100%;
        height: 100%;
        position: relative;
        align-items: center;
        place-content: center;
        display: flex;
    }

}


#pdf_page {
    // min-width: 1000px;
    // width: 95vw;
    overflow: auto;
    margin: 0 auto;
    // display: table;
    .not_data {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}