@media screen and (max-width: 1679px){
  .top-menu{
    position: fixed;
    background: #fff;
    top: 70px;
    right: -50vw;
    border-radius: 8px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
    padding: 20px 3.125vw 20px 30px;
    margin-right: 0;
    transition: all 0.3s ease;
    z-index: 10;
    &.show{
      right: 0;
    }
    ul{
      display: block;
      li{
        a{
          display: inline-block;
        }
      }
    }
  }
  .menu-toggle{
    display: block;
  }
}

@media screen and (max-width: 1199px){
  .search{
    &-icon{
      &__date{
        display: none;
      }
    }
  }
}



@media screen and (max-width: 991px){
  .logo-short{
    margin-right: 15px;
  }
  .search{
    margin-right: 15px;
    input{
      width: 200px;
    }
  }
  .top-menu{
    padding-left: 15px;
  }
}
