@import "../../assets/scss/variables";

.event-ticket__box{
  position: relative;
  display: flex;
  background: #F4F3F5;
  min-height: 76px;
  border-radius: 4px;
  overflow: hidden;
  &:not(:last-child){
    margin-bottom: 8px;
  }
  &-body{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    padding: 10px 5px 10px 15px;
  }
    &-place{
      position: relative;
      width: 50%;
      color: $colorLightText;
      font-size: 16px;
      line-height: 1.5;
      padding-right: 10px;
      b{
        color: $colorText;
      }
    }
  &-foot{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: $colorLightGrey;
    width: 140px;
    border-left: 1px dashed $colorText;
    padding: 10px;
  }
    &-price{
      position: relative;
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      padding-left: 5px;
      &:not(:last-child){
        margin-bottom: 5px;
      }
    }
    &-delete{
      position: relative;
      width: 100%;
      button{
        background: transparent;
        color: $colorLightText;
        font-size: 16px;
        border: 0;
        padding: 0;
        margin: 0;
        transition: color 0.3s ease;
        cursor: pointer;
        &:hover{
          color: $colorRed;
        }
      }
      .icon{
        font-size: 24px;
        margin-right: 10px;
      }
    }
}
