@import "../../assets/scss/variables";

.orders{
  &-button {
    background: linear-gradient(to right, #E2441F, #F2AD30);
    width: 100%;
    margin-bottom: 10px;
    height: fit-content;

  }
  &-sidebar{
    position: relative;
    background: #fff;
    width: 490px;
    border-radius: 4px;
    margin-right: -3.125vw;
    &__spinner {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      align-items: center;
    }
    &__toggle{
      position: fixed;
      display: none;
      align-items: center;
      justify-content: center;
      background: $colorGrey;
      width: 40px;
      height: 40px;
      color: #fff;
      text-align: center;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.23913);
      border-radius: 4px;
      margin-left: -40px;
      transition: all 0.3s ease;
      z-index: 1;
      cursor: pointer;
      &:hover{
        background: $colorLightText;
      }
    }
    &__scroll{
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    &__box{
      position: relative;
      width: 100%;
      padding: 25px 3.125vw 25px 25px;
      &:not(:last-child){
        border-bottom: 1px solid $colorLightLabel;
      }
      &-head{
        position: relative;
        color: $colorBlue;
        font-weight: 800;
        margin-bottom: 20px;
      }
      &-body{
        position: relative;
      }
        &-text{
          position: relative;
          line-height: 1.5;
          &:not(:last-child){
            margin-bottom: 10px;
          }
          a{
            color: $colorOrange;
            transition: color 0.3s ease;
            &:hover {
              color: $colorLightOrange;
            }
          }
        }
        &-contacts{
          position: relative;
          line-height: 1.5;
          &:not(:last-child){
            margin-bottom: 15px;
          }
          a{
            color: $colorOrange;
            transition: color 0.3s ease;
            &:hover{
              color: $colorLightOrange;
            }
            &[href^="tel"]{
              color: $colorText;
              font-weight: 700;
              &:hover{
                color: $colorLightText;
              }
            }
          }
        }
    }
    &__statistics{
      position: relative;
      display: flex;
      align-items: center;
      &-box{
        position: relative;
        display: flex;
        align-items: center;
        &:not(:last-child){
          margin-right: 30px;
        }
        &-text{
          position: relative;
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: 0;
          font-size: 12px;
          line-height: 1.5;
          padding-right: 10px;
        }
        &-count{
          position: relative;
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: auto;
          min-width: 30px;
          max-width: 100px;
          color: $colorYellow;
          font-size: 24px;
          font-weight: 300;
          line-height: 1;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    &__tabs{
      position: relative;
      &-link{
        position: relative;
        border-bottom: 1px solid $colorLightText;
        ul{
          display: flex;
          margin-bottom: -1px;
          li{
            position: relative;
            color: $colorLightText;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 0 10px 10px 0;
            transition: color 0.3s ease;
            cursor: pointer;
            &:not(:last-child){
              margin-right: 25px;
            }
            &:hover{
              color: $colorText;
            }
            &.active{
              color: $colorText;
              &:before{
                width: 100%;
              }
            }
            &:before{
              content: '';
              position: absolute;
              background: $colorOrange;
              left: 0;
              bottom: 0;
              width: 0;
              height: 3px;
              transition: all 0.3s ease;
            }
          }
        }
      }
      &-info{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;
        &-box{
          position: relative;
          width: 80px;
          color: $colorYellow;
          line-height: 1.5;
          text-align: center;
          b{
            display: block;
            font-size: 24px;
            font-weight: 300;
          }
        }
      }
    }
  }
}
