.reports-choice{
  position: relative;

  &_container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    place-content: center;
    button {
      width: 45%;
      margin: 2.5%;
      height: 80px;
      font-size: 18px;
      &:hover {
        // background: linear-gradient(to right, #E2441F, #F2AD30);
      }
    }
  }
}
