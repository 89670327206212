@import "../../assets/scss/variables";

.order-reservation{
  position: relative;
  padding-top: 10px;
  .form{
    &-flex{
      label{
        width: 280px;
        margin-right: 25px;
      }
    }
    &-error {
      color: rgb(221, 48, 48);
      padding: 0 0 10px 0px;
      font-size: 18px;
      font-weight: 500;
    }
    &-field{
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      &-error {
        border: 1px solid rgb(221, 48, 48);
      }
    }
    &-button{
      display: flex;
      .btn{
        width: 280px;
      }
    }
  }
}
