@import "../../assets/scss/variables";

.order-cashless-success{
  position: relative;
  padding-top: 10px;
  &__text{
    position: relative;
    margin-bottom: 5px;
  }
  &__number{
    position: relative;
    color: $colorYellow;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    &:not(:last-child){
      margin-bottom: 15px;
    }
  }
  &__info{
    position: relative;
    font-size: 12px;
    &:not(:last-child){
      margin-bottom: 30px;
    }
  }
  .form{
    &-button{
      display: flex;
      .btn{
        width: 280px;
        &:first-child{
          width: 200px;
        }
        &:not(:last-child){
          margin-right: 25px;
        }
      }
    }
  }
}
