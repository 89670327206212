@media screen and (max-width: 1199px){
    .reports-filter{
      &__tickets{
        display: block;
        width: 280px;
      }
        &__group{
          &:not(:last-child){
            padding-right: 0;
            margin-bottom: 30px;
          }
        }
    }
}
