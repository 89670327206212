@import "../../assets/scss/variables";

.order-cashless{
  position: relative;
  padding-top: 10px;
  input {
    width: calc(100% - 30px);
  }
  .form {
    &-scroll {
      height: calc(100vh - 250px);
      position: relative;
    }
    &-flex{
      label{
        width: 280px;
        margin-right: 25px;
      }
    }
    &-error {
      color: rgb(221, 48, 48);
      padding: 0 0 10px 0px;
      font-size: 18px;
      font-weight: 500;
    }
    &-field{
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      &-error {
        border: 1px solid $colorRed;
      }
    }
    &-button{
      display: flex;
      .btn{
        width: 280px;
      }
    }
  }
  .input-number{
    width: 130px;
  }
}

.form-error-message {
  color: rgb(221, 48, 48);
  font-size: 16px;
  font-weight: 600;
}