@media screen and (max-width: 1439px){
  .event-map__sidebar-tickets{
    width: 440px;
    padding-right: 5px;
    padding-left: 15px;
    &-list{
      max-height: calc(100vh - 180px - 220px);
      .scrollbar-container{
        padding-right: 25px;
      }
    }
  }
}
