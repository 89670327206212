@import "../../assets/scss/variables";

.event-box{
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: $colorLightText;
  font-size: 16px;
  &__title{
    position: relative;
    color: $colorBlue;
    font-weight: 800;
    overflow: hidden;
  }
  &__date{
    position: relative;
    margin-right: 15px;
  }
  &__place{
    position: relative;
    max-width: 280px;
    overflow: hidden;
  }
}
