@import "../../assets/scss/variables";

.login{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &-box{
    position: relative;
    display: flex;
    align-items: center;
    width: 390px;
    border: 1px solid $colorGrey;
    border-radius: 4px;
    padding: 50px 30px;
    overflow: hidden;
    &.show{
      .form{
        &-login{
          margin-left: -390px;
        }
      }
    }
    a{
      color: $colorOrange;
      transition: color 0.3s ease;
      &:hover{
        color: $colorLightOrange;
      }
    }
    .form{
      &-group{
        input{
          height: 48px;
        }
        [name*="pass"]{
          padding-right: 50px;
        }
      }
    }
    .checkbox{
      [type="checkbox"]{
        & ~ label{
          &:before{
            background: #fff;
          }
        }
        &:checked{
          & ~ label{
            &:before{
              background: $colorYellow;
            }
          }
        }
      }
    }
  }
}
.logo{
  position: relative;
  margin-bottom: 35px;
}
.show-pass{
  position: absolute;
  background: transparent;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  color: $colorLightText;
  font-size: 24px;
  border: 0;
  border-radius: 4px;
  transition: color 0.3s ease;
  cursor: pointer;
  &:hover{
    color: $colorText;
  }
}
.form{
  &-login,
  &-password{
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    width: 330px;
    transition: all 0.3s ease;
  }
  &-login{
    margin-right: 60px;
  }
}
