@media screen and (max-width: 1439px){
  .order{
    &-list{
      &__head{
        .order-list{
          &__col{
            padding: 10px;
          }
        }
      }
      &__foot{
        .order-list{
          &__col{
            padding: 10px;
          }
        }
      }
      &__col{
        padding: 10px;
      }
      .discount{
        margin-right: 0;
      }
    }
    &-discount{
      width: 240px;
    }
    &-price{
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 120px;
    }
  }
}

@media screen and (max-width: 1199px){
  .order{
    &-list{
      &__foot{
        padding-top: 15px;
      }
    }
  }
}
