@import "../../assets/scss/variables";

.event-orders__filter{
  position: relative;
  display: flex;
  align-items: center;
  &-select{
    position: relative;
    width: 280px;
    &:not(:last-child){
      margin-right: 25px;
    }
  }
  &-type{
    position: relative;
    width: 430px;
    &:not(:last-child){
      margin-right: 25px;
    }
  }
}
