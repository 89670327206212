.scheme {
    position: relative;
    margin-bottom: 30px;

    &-svg {
        height: 620px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        background-color: #fff;
        position: relative;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;

        & > div{
            width: auto!important;
            height: auto!important;
        }

        .spinner{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__legend {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        color: #000;

        &-header {
            display: flex;
            align-items: center;
            padding: 8px 15px;
            border-bottom: 1px solid #000;
        }
        &-body {
            display: none;
            flex-wrap: wrap;
            padding: 18px 0 7px;
        }

        &.open {
            .scheme__legend-body {
                display: flex;
            }

            .legend-switch {
                .svg-icon {
                    transform: rotate(0deg);
                }
            }

            .order{
                &-discount{
                    display: inline;
                    max-width: none;
                    white-space: normal;
                }
            }
        }
    }
    &-ticket{
        &-disabled{
           display: none;
        }
        position: absolute;
        display: flex;
        background: #fff;
        top: 50%;
        left: 50%;
        color: #000;
        border-radius: 4px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.24);
        transform: translate(-50%, -50%);
        overflow: hidden;
        z-index: 555;

        &__left{
            position: relative;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
            padding: 15px 25px;
        }

        &__title{
            position: relative;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        &__text{
            position: relative;
            font-size: 12px;
            line-height: 1.3;
            opacity: 0.4;
            margin-bottom: 15px;
        }

        &__count{
            position: relative;

            .count{
                width: 100%;
                align-items: center;
                display: flex;
                &-dec, &-inc {
                    min-width: 70px;
                    margin: 10px;
                }

            }
        }

        &__right{
            position: relative;
            width: 185px;
            border-left: 1px solid #F2AD30;
            padding: 15px 25px;
        }

        &__table{
            position: relative;
            margin-bottom: 20px;
        }

        &__row{
            position: relative;
            display: flex;
            align-items: center;
            padding: 5px 0;
        }

        &__col{
            position: relative;
            padding: 0 5px;

            &:first-child{
                width: 50px;
                padding-left: 0;
                        }

            .color, &:last-child{
                font-weight: 700;
                padding-right: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-left: 10px;
            }

            .color{
                padding: 0;
                margin: 0;
            }
        }

        &__button{
            position: relative;

            .btn{
                justify-content: flex-start;
                width: 100%;
                font-weight: 400;
                padding: 0 10px;

                .icon{
                    margin-right: 10px;
                }

                [class*="backet"]{
                    font-size: 15px;
                }
            }
        }
    }
}