@import "../../assets/scss/variables";

.modal_loader {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10000;
    background: #ffffff8a;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -30px -30px -40px;
    border-radius: 8px;
    @media(min-width: 1640px) {
        margin: -30px -50px -40px;
    }
}