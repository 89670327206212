@media screen and (max-width: 1199px){
  .event-menu{
    ul{
      li{
        &:not(:last-child){
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px){
  .event-menu{
    ul{
      li{
        &:not(:last-child){
          margin-right: 0;
        }
        a{
          font-size: 14px;
          letter-spacing: -0.01em;
        }
      }
    }
  }
}
