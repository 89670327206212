@import "../../assets/scss/variables";

.event-order__search{
  position: relative;
  margin-bottom: 15px;
  &-form{
    position: relative;
    display: flex;
    align-items: center;
  }
  &-event{
    position: relative;
    width: 280px;
    &:not(:last-child){
      margin-right: 25px;
    }
  }
  &-field{
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 735px;
    &:not(:last-child){
      margin-right: 25px;
    }
    input{
      padding-left: 50px;
    }
    .icon{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      color: #837F8D;
      font-size: 24px;
      text-align: center;
      padding-left: 15px;
    }
  }
  &-date{
    position: relative;
    width: 280px;
    &:not(:last-child){
      margin-right: 25px;
    }
    &.show{
      .event-order__search-date-modal{
        opacity: 1;
        visibility: visible;
      }
    }
    &-title{
      position: relative;
      display: flex;
      align-items: center;
      background: #fff;
      height: 40px;
      color: $colorLightText;
      border: 1px solid $colorGrey;
      border-radius: 4px;
      padding: 0 10px;
      transition: all 0.3s ease;
      &:hover{
        color: $colorText;
        border-color: $colorLightText;
      }
      .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 40px;
        font-size: 24px;
        text-align: center;
        margin-right: 5px;
      }
    }
    &-value{
      position: relative;
      color: $colorText;
      white-space: nowrap;
    }
    &-modal{
      position: absolute;
      background: #fff;
      top: calc(100% + 2px);
      left: 0;
      width: 290px;
      border-radius: 8px;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16);
      padding: 8px;
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;
      z-index: 10;
    }
  }
  .form{
    &-button{
      position: relative;
      display: flex;
      align-items: center;
      .btn{
        width: 130px;
        .icon{
          font-size: 24px;
          margin-right: 5px;
        }
      }
    }
  }
}
