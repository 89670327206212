$colorYellow: #F1A121;
$colorLightYellow: #F2AD30;
$colorOrange: #E34C20;
$colorLightOrange: #E2441F;
$colorBlue: #2E1F5D;
$colorLightBlue: #2879F2;
$colorText: #4A4755;
$colorLightText: #837F8D;
$colorGrey: #C0BCCD;
$colorLightGrey: #E9E7EE;
$colorLabel: #DCD7EB;
$colorLightLabel: #F9F9F9;
$colorGreen: #4ab020;
$colorLightGreen: #8BFF69;
$colorRed: #CA1111;
$colorLightRed: #FF4D48;
