@import "../../assets/scss/variables";

.event-panel__statistics{
  position: relative;
  display: flex;
  align-items: center;
  &-box{
    position: relative;
    color: $colorLightText;
    font-size: 16px;
    &:not(:last-child){
      margin-right: 50px;
    }
    b{
      color: $colorYellow;
      margin-left: 8px;
    }
  }
}
