@import "../../assets/scss/variables";

.event{
  // position: relative;
  &-panel{
    position: relative;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.23913);
    z-index: 1;
    &__head{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      padding: 15px 3.125vw;
      &-left{
        position: relative;
        display: flex;
        align-items: center;
      }
      &-right{
        position: relative;
        display: flex;
        align-items: center;
      }
    }
    .event-box{
      &__title{
        max-width: 280px;
        margin-right: 15px;
      }
    }
  }
  &-back{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 40px;
    height: 40px;
    color: $colorBlue;
    font-size: 40px;
    border: 0;
    padding: 0;
    margin-right: 25px;
    cursor: pointer;
    transition: color 0.3s ease;
    &:hover{
      color: $colorLightBlue;
    }
  }
  &-map{
    position: relative;
    background: $colorLightGrey;
    height: calc(100vh - 180px);
    &__sidebar{
      position: absolute;
      display: flex;
      align-items: flex-end;
      right: 0;
      bottom: 40px;
      height: 0;
      z-index: 10;
      &-plan{
        position: relative;
        display: flex;
        background: #fff;
        width: 280px;
        height: 168px;
        border-radius: 4px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.23913);
        margin-right: 25px;
        overflow: hidden;
        &-control{
          position: relative;
          display: flex;
          flex-direction: column;
          width: 40px;
          border-right: 1px solid $colorGrey;
        }
          &-zoomin,
          &-zoomout{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            color: $colorLightText;
            font-size: 24px;
            cursor: pointer;
            transition: color 0.3s ease;
            &:hover{
              color: $colorOrange;
            }
          }
          &-refresh{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
            color: $colorLightText;
            font-size: 24px;
            border-top: 1px solid $colorGrey;
            border-bottom: 1px solid $colorGrey;
            cursor: pointer;
            transition: color 0.3s ease;
            &:hover{
              color: $colorOrange;
            }
          }
        &-percent{
          position: relative;
          color: $colorLightText;
          font-size: 12px;
        }
        &-view{
          position: relative;
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: 0;
          overflow: hidden;
        }
      }
    }
  }
}
