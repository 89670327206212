@import "../../assets/scss/variables";

.order-payment-basket{
  position: relative;
  &__box{
    position: relative;
    font-size: 16px;
    padding: 30px 0;
    &:not(:last-child){
      border-bottom: 1px solid $colorLightLabel;
    }
  }
    &__row{
      position: relative;
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
    &__col{
      position: relative;
      &:first-child{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        padding-right: 15px;
      }
      &:last-child{
        width: 140px;
        text-align: right;
      }
      .price{
        color: $colorYellow;
        font-size: 24px;
        font-weight: 300;
        line-height: 1;
      }
    }
  &__bg{
    position: absolute;
    top: 70px;
    left: 0;
    color: $colorGrey;
    font-size: 280px;
    line-height: 0;
    pointer-events: none;
    opacity: 0.25;
    z-index: -1;
  }
}
