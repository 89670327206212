@media screen and (max-width: 1639px){
  .event-orders{
    &-table{
      &__thead{
        .event-orders-table{
          &__col{
            font-size: 14px;
          }
        }
      }
      .event-orders{
        &-status{
          width: 120px;
        }
        &-date{
          width: 140px;
        }
        &-return{
          width: 140px;
        }
        &-tickets{
          width: 120px;
        }
        &-print{
          width: 240px;
        }
      }
    }
  }
}

@media screen and (max-width: 1439px){
  .event-orders{
    &-table{
      .event-orders{
        &-checkbox {
          width: 35px;
        }
        &-number{
          width: 90px;
        }
        &-status{
          width: 100px;
        }
        &-date{
          width: 160px;
        }
        &-return{
          width: 160px;
        }
        &-tickets{
          width: 100px;
        }
        &-payment{
          width: 120px;
        }
        &-print{
          width: 180px;
        }
      }
    }
  }
}
