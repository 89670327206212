@import "../../assets/scss/variables";

.order-payment{
  position: relative;
  padding-top: 10px;
  &__form{
    position: relative;
    max-width: 740px;
  }
    &__group{
      position: relative;
      border-bottom: 1px solid $colorLightLabel;
      padding: 20px 0;
    }
    &__row{
      position: relative;
      display: flex;
      align-items: center;
    }
    &__col{
      position: relative;
      padding: 12px 10px;
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  .form{
    &-button{
      display: flex;
      .btn{
        width: 280px;
      }
    }
  }
}
.payment{
  &-error {
    color: rgb(221, 48, 48);
    padding: 0 0 10px 0px;
    font-size: 18px;
    font-weight: 500;
  }
  &-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    color: $colorLightText;
    font-size: 40px;
    text-align: center;
  }
  &-title{
    width: 240px;
  }
  &-field{
    &-error {
      border: 1px solid rgb(221, 48, 48);
    }
    width: 150px;
    text-transform: uppercase;
    input{
      text-transform: uppercase;
    }
    .currency{
      position: absolute;
      top: 50%;
      right: 15px;
      color: $colorLightText;
      transform: translateY(-50%);
      pointer-events: none;
      z-index: 1;
      & ~ input{
        text-align: right;
        padding-right: 55px;
      }
    }
    button{
      position: absolute;
      top: 50%;
      right: 10px;
      background: transparent;
      width: 40px;
      height: 40px;
      color: #4A4755;
      font-size: 24px;
      border: 0;
      padding: 0;
      cursor: pointer;
      transform: translateY(-50%);
      transition: color 0.3s ease;
      z-index: 1;
      &:hover{
        color: $colorOrange;
      }
      & ~ input{
        padding-right: 40px;
      }
    }
  }
  &-price{
    width: 150px;
    text-align: right;
  }
}
