@media screen and (max-width: 1439px){

}

@media screen and (max-width: 1199px){
  .event-box{
    flex-wrap: wrap;
    width: 320px;
    &__title{
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__place{
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
