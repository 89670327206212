@import "../../assets/scss/variables";

.order-info{
  position: relative;
  &:not(:last-child){
    margin-bottom: 30px;
  }
  .modal-text{
    font-size: 12px;
  }
}
