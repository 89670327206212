@import "../../assets/scss/variables";

.layout-ticket{
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 0;
  &:not(:last-child){
    margin-bottom: 10px;
  }
  &__title{
    position: relative;
    width: 145px;
    padding-right: 10px;
  }
  &__field{
    position: relative;
    width: 280px;
  }
}
