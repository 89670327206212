@media screen and (max-width: 1679px){
  .orders{
    &-sidebar{
      width: 420px;
      &__statistics{
        &-box{
          &-text{
            line-height: 1.25;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1439px){
  .orders{
    &-sidebar{
      position: fixed;
      top: 100px;
      right: -420px;
      margin-right: 0;
      transition: all 0.3s ease;
      z-index: 10;
      &.show{
        right: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.23913);
        &:before{
          content: '';
          position: absolute;
          background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,0) 100%);
          top: 0;
          left: 0;
          right: 0;
          height: 30px;
          z-index: 1;
        }
        &:after{
          content: '';
          position: absolute;
          background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,0) 100%);
          bottom: 0;
          left: 0;
          right: 0;
          height: 30px;
        }
        .orders-sidebar{
          &__toggle{
            .icon{
              font-size: 30px;
              &:before{
                content: '\e908';
              }
            }
          }
        }
      }
      &__scroll{
        max-height: calc(100vh - 130px);
      }
      &__toggle{
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .orders{
    &-sidebar{
      &__box{
        padding: 20px 3.125vw 20px 15px;
        &-head{
          margin-bottom: 15px;
        }
      }
      &__tabs{
        &-link{
          ul{
            li{
              &:not(:last-child){
                margin-right: 20px;
              }
            }
          }
        }
        &-info{
          padding-top: 15px;
        }
      }
    }
  }
}
