@import "../../assets/scss/variables";

.event-orders{
  &-list{
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    margin-right: 25px;
  }
  &-table {
    position: relative;
    &__thead {
      position: relative;
      margin-bottom: 1px;
      .event-orders-table{
        &__row {
          background: $colorLightGrey;
          min-height: 40px;
          &_active {
            background: #f1a72f;
          }
        }
        &__col{
          color: $colorLightText;
        }
      }
    }
    &__tbody{
      position: relative;
      .event-orders-table{
        &__row{
          &_active {
            background: #f1a72f;
            div {
              color: #3c3636;
            }
          }
          &:hover{
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.24);
            z-index: 1;
          }
        }
      }
      .event-orders{
        &-name{
          font-weight: 700;
        }
        &-status,
        &-payment,
        &-print{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    &__row{
      position: relative;
      display: flex;
      align-items: center;
      background: #fff;
      min-height: 64px;
      color: $colorText;
      border-radius: 4px;
      transition: all 0.3s ease;
      overflow: hidden;
      z-index: 0;
      &:not(:last-child){
        margin-bottom: 1px;
      }
      &:hover{
        .btn{
          right: 8px;
        }
      }
    }
    &__col{
      position: relative;
      padding: 8px 8px;
    }
    .event-orders{
      &-number{
        width: 100px;
      }
      &-status{
        width: 135px;
        color: $colorLightText;
      }
      &-date{
        width: 180px;
        color: $colorLightText;
      }
      &-return{
        width: 180px;
        color: $colorLightText;
      }
      &-name{
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
      }
      &-tickets{
        width: 150px;
        color: $colorLightText;
      }
      &-payment{
        width: 150px;
        color: $colorLightText;
      }
      &-print{
        width: 300px;
        color: $colorLightText;
        b{
          color: $colorOrange;
          font-weight: 400;
        }
      }
    }
    [class*="sort"]{
      font-size: 24px;
      transition: color 0.3s ease;
      cursor: pointer;
      &:hover{
        color: $colorText;
      }
    }
    .btn{
      position: absolute;
      top: 8px;
      bottom: 8px;
      right: -150px;
      width: 120px;
      z-index: 1;
    }
  }
}


//
.test {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 54545;
}